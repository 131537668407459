
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    confirmTitle: {
      type: String,
      default: "",
    },
    cancelTitle: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
})
export default class ButtonsWrap extends Vue {
  cancelTitle: string;

  get cancelText(): string {
    return this.cancelTitle.length > 0 ? this.cancelTitle : this.$t("cancel");
  }
}
