export class Unsubscribe {
  type: string;
  description: string;

  constructor(props: any) {
    this.set(props);
  }

  static create(props: any): Unsubscribe {
    return new Unsubscribe(props)
  }

  set(props: any): void {
    this.setDefaultData();

    if (!props) return;

    this.type = props.type ?? "";
    this.description = props.description ?? "";
  }

  setDefaultData(): void {
    this.type = "";
    this.description = "";
  }

  static createDefault(): Unsubscribe {
    return new Unsubscribe({
      type: "",
      description: ""
    });
  }

  getJsonObj() {
    return {
      type: this.type,
      description: this.description,
    }
  }
}