import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "buttons-wrapper" }
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "primary-rewards-btn",
      disabled: _ctx.disabled,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('confirm')))
    }, _toDisplayString(_ctx.confirmTitle), 9, _hoisted_2),
    _createElementVNode("a", {
      class: "violet-link",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel')))
    }, _toDisplayString(_ctx.cancelText), 1)
  ]))
}