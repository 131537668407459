
import { Options, Vue } from "vue-class-component"
import CustomCheckbox from "@/components/Checkbox/CustomCheckbox.vue"
import UnsubscribeService from "@/api/services/UnsubscribeService"
import { Unsubscribe } from "@/models/Unsubscribe"
import ButtonsWrap from "../Button/ButtonsWrap.vue"
import { goToPortalHomePage } from "@/api/services/InviteService"


@Options({
  components: {
    CustomCheckbox,
    ButtonsWrap
  }
})
export default class UnsubscribeForm extends Vue {
  selectedReasons = ""
  comment = ""
  submitted = false

  reasons = [
    {
      value: "tooManyReasons",
      label: "Too many invitations"
    },
    {
      value: "studiesNotRelevant",
      label: "Studies are not relevant to me"
    },
    {
      value: "notInterestedAnymore",
      label: "I’m not interested anymore"
    }
  ]

  get id(): string {
    return this.$route.query.id as string
  }

  get contactId(): string {
    return this.$route.query.contactId as string ?? undefined
  }

  get anotherOptionSelected(): boolean {
    return this.selectedReasons.includes("another")
  }

  onChange({ value, checked }: { value: string; checked: boolean }): void {
    this.selectedReasons = checked ? value : ""
    if (checked) {
      this.selectedReasons = value
      this.comment = ""
    } else {
      this.selectedReasons = ""
    }
  }

  async onConfirm() {
    const payload: Unsubscribe = Unsubscribe.create({
      type: this.selectedReasons,
      description: this.comment
    })

    UnsubscribeService.confirmById(this.id, this.contactId, payload)

    this.submitted = true
  }

  onCancel() {
    goToPortalHomePage()
  }
}
