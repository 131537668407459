
import { Options, Vue } from "vue-class-component"
import UnsubscribeForm from "@/components/Unsubscribe/Form.vue"
import { goToPortalSignIn } from "@/api/services/InviteService"

@Options({
  components: {
    UnsubscribeForm
  }
})
export default class Reasons extends Vue {

  toSignIn(): void {
    goToPortalSignIn()
  }
}
