import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "unsubscribe-form" }
const _hoisted_2 = {
  key: 0,
  class: "h3"
}
const _hoisted_3 = { class: "h3 unsubscribe-form__title" }
const _hoisted_4 = { class: "unsubscribe-form__checkbox-wrapper" }
const _hoisted_5 = { class: "unsubscribe-form__another-checkbox" }
const _hoisted_6 = ["placeholder", "disabled"]
const _hoisted_7 = { class: "unsubscribe-form__buttons-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomCheckbox = _resolveComponent("CustomCheckbox")!
  const _component_ButtonsWrap = _resolveComponent("ButtonsWrap")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.submitted)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t("unsubscribe.reasons.submitted")), 1))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("unsubscribe.reasons.form-message")), 1),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reasons, (reason, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass(["unsubscribe-form__checkbox", [
            {
              'unsubscribe-form__checkbox_checked': _ctx.selectedReasons.includes(
                reason.value
              )
            }
          ]])
              }, [
                _createVNode(_component_CustomCheckbox, {
                  id: `unsubscribe-form-reason_${index}`,
                  checked: _ctx.selectedReasons.includes(reason.value),
                  value: reason.value,
                  label: _ctx.$t(`unsubscribe.reasons.data.${reason.value}`),
                  onOnChange: _ctx.onChange
                }, null, 8, ["id", "checked", "value", "label", "onOnChange"])
              ], 2))
            }), 128)),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", {
                class: _normalizeClass(["unsubscribe-form__checkbox", [
              {
                'unsubscribe-form__checkbox_checked':
                  _ctx.selectedReasons.includes('another')
              }
            ]])
              }, [
                _createVNode(_component_CustomCheckbox, {
                  id: `unsubscribe-form-reason_3`,
                  checked: _ctx.selectedReasons.includes('another'),
                  value: "another",
                  label: _ctx.$t(`unsubscribe.reasons.data.another`),
                  onOnChange: _ctx.onChange
                }, null, 8, ["checked", "label", "onOnChange"])
              ], 2),
              _withDirectives(_createElementVNode("textarea", {
                placeholder: _ctx.$t(`unsubscribe.reasons.anotherPlaceholder`),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.comment) = $event)),
                disabled: !_ctx.anotherOptionSelected,
                class: _normalizeClass(["unsubscribe-form__textarea", [
              {
                'unsubscribe-form__textarea_checked':
                  _ctx.selectedReasons.includes('another')
              }
            ]])
              }, null, 10, _hoisted_6), [
                [_vModelText, _ctx.comment]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_ButtonsWrap, {
              confirmTitle: _ctx.$t('submit'),
              cancelTitle: _ctx.$t('cancel'),
              disabled: !_ctx.selectedReasons.length,
              onConfirm: _ctx.onConfirm,
              onCancel: _ctx.onCancel
            }, null, 8, ["confirmTitle", "cancelTitle", "disabled", "onConfirm", "onCancel"])
          ])
        ], 64))
  ]))
}