import axios from "axios"
import { Endpoint } from "@/api/endpoints"
import { Unsubscribe } from "@/models/Unsubscribe"
import Common from "./Common"

export default class UnsubscribeService extends Common {
  static async getById(id: string): Promise<void | undefined> {
    try {
      const { data } = await axios.get(Endpoint.CHECK_UNSUBSCRIBE().url, {
        params: { id }
      })

      return data.data.contact.response
    } catch (error) {
      super.toError(error)
    }
  }

  static async confirmById(
    id: string,
    contactId: string,
    payload: Unsubscribe
  ): Promise<Unsubscribe | undefined> {
    try {
      await axios.post(Endpoint.SET_UNSUBSCRIBE().url, payload.getJsonObj(), {
        params: { id, contactId }
      })

      return
    } catch (error) {
      super.toError(error)
    }
  }

  static async unsubscribeDailyDigestById(
    id: string,
    payload: Unsubscribe
  ): Promise<Unsubscribe | undefined> {
    try {
      await axios.post(
        Endpoint.UNSUBSCRIBE_DAILY_DIGEST().url,
        payload.getJsonObj(),
        {
          params: { id }
        }
      )

      return
    } catch (error) {
      super.toError(error)
    }
  }
}

export const getUnsubscribe = UnsubscribeService.getById
export const confirmUnsubscribe = UnsubscribeService.confirmById
export const unsubscribeDailyDigest =
  UnsubscribeService.unsubscribeDailyDigestById
